/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Mher Ter-Tovmasyan, Emily Calvet,
 * Milad Chatrangoon, Steffen Hupp, Philipp Ewen, Pedram (Majid) Jokar, Bestin John
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import {
  SLRPrompt,
  SLRPromptProps,
  SLRSpinner,
  hasValue,
  isEmptyOrNull,
  useAuth,
  useFindMembershipsOfCurrentUser
} from '@SLR/shared-library';
import {
  OrganizationType,
  UserMembershipInfo
} from '@SLR/user-service-external-sdk';
import { MARKETPLACE_MANAGEMENT_PATH, isCreateOrEditPath } from 'configs';
import { t } from 'i18next';
import { useMarketplace } from 'providers';
import {
  FC,
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useLocation, useNavigate } from 'react-router';

export type OrganizationContextValues = {
  hasAccessByOrganization?: boolean;
  isSolutionProvider?: boolean;
  isOrganizationChangeNavigate?: boolean;
  availableOrganizations?: UserMembershipInfo[];
  selectedOrganization?: UserMembershipInfo;
  selectedOrganizationId: string;
  selectOrganization: (id?: string) => void;
};

const defaultOrganizationContextValues: OrganizationContextValues = {
  hasAccessByOrganization: false,
  isSolutionProvider: false,
  isOrganizationChangeNavigate: false,
  selectedOrganizationId: '',
  selectOrganization: () => {}
};

const OrganizationContext = createContext<OrganizationContextValues>(
  defaultOrganizationContextValues
);

export const useOrganization = () => useContext(OrganizationContext);

export const OrganizationProvider: FC<PropsWithChildren> = ({ children }) => {
  // #region Local storage
  const storedSelectedOrganizationId =
    localStorage.getItem('selectedOrganizationId') ?? undefined;

  const storeSelectedOrganizationId = (id: string) =>
    localStorage.setItem('selectedOrganizationId', id);
  // #endregion

  const { pathname } = useLocation();
  const navigate = useNavigate();

  const { isAuthenticated } = useAuth();
  const { hasAccessByRole, isManagementContext } = useMarketplace();

  const { data: organizations, isPending: isOrganizationLoading } =
    useFindMembershipsOfCurrentUser();

  const [selectedOrganization, setSelectedOrganization] = useState<
    UserMembershipInfo | undefined
  >();

  const availableOrganizations = useMemo(
    () =>
      organizations?.filter(
        (o) =>
          o.organizationType === OrganizationType.Customer ||
          o.organizationType === OrganizationType.SolutionProvider
      ),
    [organizations]
  );

  const availableManagementOrganizations = useMemo(
    () =>
      organizations?.filter(
        (o) => o.organizationType === OrganizationType.SolutionProvider
      ),
    [organizations]
  );

  useEffect(
    () =>
      setSelectedOrganization(
        availableOrganizations?.find(
          (o) => o.organizationId === storedSelectedOrganizationId
        ) ??
          availableOrganizations?.[0] ??
          undefined
      ),
    [availableOrganizations, storedSelectedOrganizationId]
  );

  // Show loading indicator when user is logged in and organizations are still loading or the user is not slr admin and there is no selected organization
  const isLoading = useMemo(
    () =>
      isAuthenticated &&
      (isOrganizationLoading ||
        (!hasAccessByRole &&
          !selectedOrganization &&
          hasValue(availableOrganizations))),
    [
      availableOrganizations,
      hasAccessByRole,
      isAuthenticated,
      isOrganizationLoading,
      selectedOrganization
    ]
  );

  const hasAccessByOrganization = useMemo(
    () =>
      (hasValue(availableOrganizations) && Boolean(selectedOrganization)) ||
      hasAccessByRole,
    [availableOrganizations, hasAccessByRole, selectedOrganization]
  );

  const isSolutionProvider = useMemo(
    () =>
      selectedOrganization?.organizationType ===
      OrganizationType.SolutionProvider,
    [selectedOrganization]
  );

  const selectedOrganizationId = useMemo(
    () => selectedOrganization?.organizationId ?? '',
    [selectedOrganization?.organizationId]
  );

  // #region Handle organization change
  const [promptData, setPromptData] = useState<SLRPromptProps | null>(null);

  const [isOrganizationChangeNavigate, setIsOrganizationChangeNavigate] =
    useState<boolean>();

  const handleSelectOrganization = useCallback(
    (organization: UserMembershipInfo) => {
      setSelectedOrganization(organization);
      storeSelectedOrganizationId(organization.organizationId);
    },
    []
  );

  const selectOrganization = useCallback(
    (id?: string) => {
      // Do nothing in case id is empty, user has no organizations or user selects already selected organization
      if (
        isEmptyOrNull(id) ||
        !hasValue(availableOrganizations) ||
        id === selectedOrganizationId
      )
        return;

      const organization = availableOrganizations?.find(
        (o) => o.organizationId === id
      );

      if (!organization) return;

      // In case user is on create or edit page, show confirmation prompt
      if (isManagementContext && isCreateOrEditPath(pathname)) {
        setIsOrganizationChangeNavigate(true);
        setPromptData({
          labels: t('navigation.preventOrganizationChange', {
            returnObjects: true
          }),
          onConfirm: () => {
            handleSelectOrganization(organization);
            navigate(MARKETPLACE_MANAGEMENT_PATH, { replace: true });
            setPromptData(null);
            setIsOrganizationChangeNavigate(false);
          },
          onCancel: () => {
            setIsOrganizationChangeNavigate(false);
            setPromptData(null);
          }
        });
      } else {
        handleSelectOrganization(organization);
      }
    },
    [
      availableOrganizations,
      handleSelectOrganization,
      isManagementContext,
      navigate,
      pathname,
      selectedOrganizationId
    ]
  );

  const organizationContextValues: OrganizationContextValues = useMemo(
    () => ({
      hasAccessByOrganization,
      isSolutionProvider,
      isOrganizationChangeNavigate,
      availableOrganizations: isManagementContext
        ? availableManagementOrganizations
        : availableOrganizations,
      selectedOrganization,
      selectedOrganizationId,
      selectOrganization
    }),
    [
      hasAccessByOrganization,
      isSolutionProvider,
      isOrganizationChangeNavigate,
      isManagementContext,
      availableManagementOrganizations,
      availableOrganizations,
      selectedOrganization,
      selectedOrganizationId,
      selectOrganization
    ]
  );

  return (
    <OrganizationContext.Provider value={organizationContextValues}>
      {promptData && <SLRPrompt {...promptData} />}
      {isLoading ? <SLRSpinner /> : children}
    </OrganizationContext.Provider>
  );
};
