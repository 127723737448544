/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Mher Ter-Tovmasyan
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getIcon } from '@SLR/shared-library';
import { useOutsideClick } from 'hooks';
import { useMarketplace } from 'providers';
import { FC, useState } from 'react';
import {
  Button,
  Container,
  ContainerProps,
  Form,
  InputGroup
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import './SearchBar.scss';

export type SearchBarProps = {
  className?: ContainerProps['className'];
  isLiveSearch?: boolean;
  shouldAutoFocus?: boolean;
  onClose?: () => void;
};

export const SearchBar: FC<SearchBarProps> = ({
  className = '',
  isLiveSearch = true,
  shouldAutoFocus = true,
  onClose
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'marketplace.filters.search'
  });

  const searchBarRef = useOutsideClick(onClose);

  const { selectedSearch, setSearch } = useMarketplace();

  const [isFocused, setIsFocused] = useState<boolean>(false);

  return (
    <Container
      ref={searchBarRef}
      className={`search-bar px-0 p-md-4 ${className}`}
    >
      <InputGroup className={isFocused ? 'is-focused' : ''}>
        <InputGroup.Text className="border-0">
          <Form.Label
            htmlFor="search"
            title={t('placeholder')}
            aria-label={t('placeholder')}
            className="m-0 d-flex search-icon"
          >
            <FontAwesomeIcon
              icon={getIcon('fal', 'magnifying-glass')}
              size="2x"
            />
          </Form.Label>
        </InputGroup.Text>

        <Form.Control
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus={shouldAutoFocus}
          id="search"
          type="search"
          placeholder={t('placeholder')}
          className="form-control-lg border-0"
          value={selectedSearch}
          autoComplete="search"
          onChange={(e) => setSearch(e.target.value)}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
        />
        {selectedSearch && selectedSearch.length > 0 && (
          <Button
            variant="link"
            className="search-clear-icon"
            title={t('clear')}
            onClick={() => setSearch('')}
          >
            <FontAwesomeIcon icon={getIcon('fal', 'xmark')} size="2x" />
          </Button>
        )}
        {!isLiveSearch && (
          <Button className="search-button" title={t('startSearch')}>
            Los
          </Button>
        )}
      </InputGroup>
    </Container>
  );
};
