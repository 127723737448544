/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Mher Ter-Tovmasyan, Emily Calvet,
 * Milad Chatrangoon, Steffen Hupp, Philipp Ewen, Pedram (Majid) Jokar, Bestin John
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Breakpoint,
  getIcon,
  isEmptyOrNull,
  useWindowSize
} from '@SLR/shared-library';
import { useMarketplace } from 'providers';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Badge, Button, Col, Container, Dropdown, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { SearchBar } from './search';

import './FilterSort.scss';

export const FilterSort: FC = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'marketplace.filters'
  });

  const { breakpoint } = useWindowSize();

  const {
    selectedSearch,
    setSearch,
    selectedFilter,
    filters,
    setFilter,
    selectedSortMode,
    sortModes,
    setSortMode
  } = useMarketplace();

  const [showSearchOverlay, setShowSearchOverlay] = useState<boolean>(false);
  const showSearchBar = useMemo(
    () => breakpoint === Breakpoint.XS || breakpoint === Breakpoint.SM,
    [breakpoint]
  );

  useEffect(() => {
    if (showSearchBar) setShowSearchOverlay(false);
  }, [showSearchBar]);

  const handleEsc = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        if (showSearchOverlay && !isEmptyOrNull(selectedSearch)) setSearch('');
        setShowSearchOverlay(false);
      }
      if (event.key === 'Enter') setShowSearchOverlay(false);
    },
    [selectedSearch, setSearch, showSearchOverlay]
  );

  useEffect(() => {
    document.addEventListener('keydown', handleEsc);
    return () => document.removeEventListener('keydown', handleEsc);
  }, [handleEsc]);

  return (
    <Container className="filter-sort">
      {showSearchOverlay && (
        <SearchBar
          className="search-bar-overlay"
          onClose={() => setShowSearchOverlay(false)}
        />
      )}

      <Row className="filter-sort-row gap-3">
        <Col xs="auto" className="p-0">
          <Dropdown id="category" className="category-dropdown">
            <Dropdown.Toggle
              variant="link"
              size="lg"
              className="category-toggle fs-1"
            >
              {selectedFilter}
            </Dropdown.Toggle>

            <Dropdown.Menu className="category-menu">
              {filters.map((filter) => (
                <Dropdown.Item
                  active={filter === selectedFilter}
                  key={filter}
                  className="category-item"
                  onClick={() => setFilter(filter)}
                >
                  {filter}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </Col>

        <Col xs="auto" className="p-0 d-flex flex-row align-items-center gap-4">
          {!showSearchBar && (
            <Button
              variant="link"
              onClick={(e) => {
                setShowSearchOverlay(true);
                e.stopPropagation();
              }}
              title={t('search.startSearch')}
            >
              <FontAwesomeIcon
                icon={getIcon('fal', 'magnifying-glass')}
                size="2x"
              />
            </Button>
          )}
          <Dropdown id="sort" className="sort-dropdown">
            <Dropdown.Toggle
              variant="outline-primary"
              size="lg"
              className="sort-toggle"
            >
              {t(`sort.${selectedSortMode}`)}
            </Dropdown.Toggle>
            <Dropdown.Menu className="w-100">
              {sortModes.map((sortMode) => (
                <Dropdown.Item
                  key={sortMode}
                  className="sort-item"
                  active={sortMode === selectedSortMode}
                  onClick={() => setSortMode(sortMode)}
                >
                  {t(`sort.${sortMode}`)}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </Col>

        {showSearchBar && (
          <Col xs="12" className="p-0">
            <SearchBar shouldAutoFocus={false} />
          </Col>
        )}

        {!showSearchBar && !isEmptyOrNull(selectedSearch) && (
          <Col xs="12">
            <Row className="gap-2 align-items-center">
              <Col xs="auto" className="p-0">
                {t('resultsFor')}
              </Col>
              <Col xs="auto" className="p-0">
                <Badge
                  bg="primary"
                  className="d-flex flex-row justify-content-center align-items-center gap-2"
                >
                  {selectedSearch}
                  <Button
                    variant="link"
                    className="p-0  search-term-clear-icon"
                    title={t('search.clear')}
                    onClick={() => setSearch('')}
                  >
                    <FontAwesomeIcon icon={getIcon('fal', 'circle-xmark')} />
                  </Button>
                </Badge>
              </Col>
            </Row>
          </Col>
        )}
      </Row>
    </Container>
  );
};
