/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Mher Ter-Tovmasyan, Emily Calvet,
 * Milad Chatrangoon, Steffen Hupp, Philipp Ewen, Pedram (Majid) Jokar, Bestin John
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import {
  EntryDto,
  EntryDtoCategoriesEnum,
  EntryDtoStatusEnum
} from '@SLR/marketplaceService-sdk';
import { isEmptyOrNull } from '@SLR/shared-library';
import { EntryDtoReviewStatusEnum, SortMode } from 'configs';
import { t } from 'i18next';
import { orderBy } from 'lodash';
import { useMarketplace } from 'providers';
import { useMemo } from 'react';

export type PagedEntryDto = {
  entries: EntryDto[];
  totalCount: number;
  searchCount: number;
};

export const useEntries = () => {
  const {
    sizePerPage,
    selectedPage,
    selectedSearch,
    selectedFilter,
    selectedSortMode,
    isReviewContext,
    isManagementContext
  } = useMarketplace();

  const search = useMemo(
    () => selectedSearch.toLocaleLowerCase().trim(),
    [selectedSearch]
  );

  const sort = (entries: EntryDto[]): EntryDto[] => {
    if (
      selectedSortMode === SortMode.Newest ||
      selectedSortMode === SortMode.Oldest
    ) {
      return orderBy(
        entries,
        [
          isReviewContext
            ? 'updatedAt'
            : isManagementContext
              ? 'created'
              : 'publishedAt'
        ],
        [selectedSortMode === SortMode.Oldest ? 'asc' : 'desc']
      );
    } else if (selectedSortMode === SortMode.LastEdited) {
      return orderBy(entries, ['updatedAt'], ['desc']);
    } else {
      return orderBy(
        entries,
        [(entry) => entry?.name?.toLowerCase()],
        [selectedSortMode === SortMode.Asc ? 'asc' : 'desc']
      );
    }
  };

  const onSelect = (entries: EntryDto[]): PagedEntryDto => {
    const filtered = entries?.filter((entry) => {
      if (selectedFilter === t('marketplace.filters.all'))
        // In case user is in review context do not show drafts
        return isReviewContext && entry?.status === EntryDtoStatusEnum.Entwurf
          ? false
          : true;
      else {
        if (isReviewContext) {
          return (
            (entry?.status === EntryDtoStatusEnum.InPrfung &&
              selectedFilter === EntryDtoReviewStatusEnum.ToBeChecked) ||
            (entry?.status === EntryDtoStatusEnum.NderungenNotwendig &&
              selectedFilter === EntryDtoReviewStatusEnum.Declined) ||
            ((entry?.status === EntryDtoStatusEnum.BereitZumVerffentlichen ||
              entry?.status === EntryDtoStatusEnum.Verffentlicht) &&
              selectedFilter === EntryDtoReviewStatusEnum.Accepted)
          );
        } else if (isManagementContext) {
          return entry?.status === (selectedFilter as EntryDtoStatusEnum);
        } else {
          return entry?.categories?.includes(
            selectedFilter as EntryDtoCategoriesEnum
          );
        }
      }
    });

    const searched: EntryDto[] = isEmptyOrNull(search)
      ? filtered
      : filtered.filter(
          (entry) =>
            entry.name?.toLocaleLowerCase().includes(search) ||
            entry.shortDescription?.toLocaleLowerCase().includes(search)
        );

    const sorted = sort(searched).slice(
      (selectedPage - 1) * sizePerPage,
      selectedPage * sizePerPage
    );

    return {
      entries: sorted,
      totalCount: entries.length,
      searchCount: searched.length
    };
  };

  return { onSelect };
};
