/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Mher Ter-Tovmasyan
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import { ExperienceReportDto } from '@SLR/marketplaceService-sdk';
import { SLRSpinnerOverlay, hasValue } from '@SLR/shared-library';
import { Heart } from 'assets';
import { useGetEntry } from 'hooks';
import { useOrganization } from 'providers';
import { FC, useCallback, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import {
  ExperienceContactModal,
  ExperienceContactModalProps,
  Report,
  ReportForm,
  ReportModal,
  ReportModalProps
} from '.';

import './ExperienceReport.scss';

export const ExperienceReport: FC = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'marketplace.entry.detail.ratings.experience'
  });

  const { selectedOrganizationId, selectedOrganization, isSolutionProvider } =
    useOrganization();

  const { entryId } = useParams();
  const { data: entry } = useGetEntry(entryId);
  const entryName = entry?.name;
  const experienceReports = entry?.experienceReports?.sort((reportA) =>
    reportA.organizationId === selectedOrganizationId ? -1 : 0
  );

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

  const [reportFormProps, setReportFormProps] =
    useState<ReportModalProps | null>(null);
  const handleHideReportForm = () => setReportFormProps(null);

  const handleShowReportForm = useCallback(
    (report: ExperienceReportDto) =>
      setReportFormProps({
        entryId,
        entryName,
        organizationName: selectedOrganization?.organizationName,
        report,
        onClose: handleHideReportForm
      }),
    [entryId, entryName, selectedOrganization?.organizationName]
  );

  const [contactFormProps, setContactFormProps] =
    useState<ExperienceContactModalProps | null>(null);
  const handleHideContactForm = () => setContactFormProps(null);

  const handleShowContactForm = useCallback(
    (report: ExperienceReportDto) =>
      setContactFormProps({
        reportId: report.id,
        organizationId: report.organizationId,
        onClose: handleHideContactForm
      }),
    []
  );

  return (
    <>
      {reportFormProps && <ReportModal {...reportFormProps} />}
      {contactFormProps && <ExperienceContactModal {...contactFormProps} />}
      <Row className="gap-4 justify-content-between experience-report">
        <Col xs="12">
          <Row className="gap-3">
            <Col xs="12">
              <h3>{t('title', { solution: entryName })}</h3>
            </Col>
            <Col xs="12">
              <Trans
                t={t}
                i18nKey="description"
                values={{ solution: entryName }}
              />
            </Col>
          </Row>
        </Col>
        {hasValue(experienceReports) ? (
          <Col xs="12" className="py-2">
            <Row>
              {experienceReports.map((report) => (
                <Col xs="12" lg="6" key={report.id}>
                  <Report
                    report={report}
                    onContact={() => handleShowContactForm(report)}
                    onEdit={() => handleShowReportForm(report)}
                  />
                </Col>
              ))}
            </Row>
          </Col>
        ) : (
          <Col xs="12" className="py-2">
            <Row>
              <Col xs="12" lg="6">
                <Report isEmptyState />
              </Col>
              <Col xs="12" lg="6" className="d-flex align-items-center empty">
                {t('empty')}
              </Col>
            </Row>
          </Col>
        )}
        {(isSubmitted ||
          (!experienceReports?.some(
            (e) => e.organizationId === selectedOrganizationId
          ) &&
            selectedOrganization !== undefined &&
            !isSolutionProvider)) && (
          <Col className="pt-4">
            <Row className="justify-content-center">
              <Col md="12" lg="10">
                <SLRSpinnerOverlay active={isLoading}>
                  <Card className="border-0 experience-input">
                    <Card.Body>
                      {isSubmitted ? (
                        <Row className="gap-5 text-center ">
                          <Col xs="12">
                            <h3 className="text-white">
                              {t('confirmation.title')}
                            </h3>
                          </Col>
                          <Col xs="12">
                            <Heart />
                          </Col>
                          <Col xs="12" className="text-white">
                            <Trans t={t} i18nKey="confirmation.description" />
                          </Col>
                        </Row>
                      ) : (
                        <Row className="gap-3">
                          <Col xs="12">
                            <h3 className="text-white">
                              {t('form.title', { solution: entryName })}
                            </h3>
                          </Col>
                          <Col xs="12" className="text-white">
                            <Trans
                              t={t}
                              i18nKey="form.description"
                              values={{
                                organization:
                                  selectedOrganization?.organizationName,
                                solution: entryName
                              }}
                            />
                            <Col className="pt-4">
                              <ReportForm
                                entryId={entryId}
                                entryName={entryName}
                                onLoading={setIsLoading}
                                onSuccess={() => {
                                  setIsSubmitted(true);
                                  setIsLoading(false);
                                }}
                              />
                            </Col>
                          </Col>
                        </Row>
                      )}
                    </Card.Body>
                  </Card>
                </SLRSpinnerOverlay>
              </Col>
            </Row>
          </Col>
        )}
      </Row>
    </>
  );
};
