/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Mher Ter-Tovmasyan, Emily Calvet,
 * Milad Chatrangoon, Steffen Hupp, Philipp Ewen, Pedram (Majid) Jokar, Bestin John
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

const declarationOptions = {
  acceptability: {
    label: 'Barrierefreiheit',
    description: 'Vorgaben und Standards gemäß <0>BITV 2.0</0> werden befolgt.'
  },
  privacy: {
    label: 'Datenschutz',
    description:
      'Vorgaben und Standards gemäß <0>Datenschutz-Grundverordnung</0> (DSGVO) werden befolgt.'
  },
  security: {
    label: 'IT-Sicherheit',
    description:
      'Vorgaben und Standards gemäß <0>BSI IT Grundschutz</0> werden befolgt.'
  },
  openSource: {
    label: 'Open Source',
    description:
      'Open Source Bedingungen gemäß Vorgabe von <0>Open CoDE</0> sind erfüllt.'
  }
};

export const marketplace = {
  hero: {
    newsItem: {
      pictureAlt: 'Bild der Anzeige zu {{header}}',
      learnMore: 'Mehr erfahren'
    }
  },
  pageHead: {
    management: {
      title: 'Marktplatzeinträge',
      description: 'Erstelle und verwalte deine Angebote.'
    },
    review: {
      title: 'Eingereichte Einträge',
      description: 'Prüfe und verwalte eingereichte Marktplatzeinträge.'
    }
  },
  filters: {
    all: 'Alle',
    reviewStatus: {
      toBeChecked: 'Prüfung ausstehend',
      rejected: 'Abgelehnt',
      accepted: 'Akzeptiert'
    },
    search: {
      placeholder: 'Suchbegriff eingeben...',
      clear: 'Suche Zurücksetzten Taste',
      startSearch: 'Suche Starten Taste'
    },
    sort: {
      newest: 'Neueste zuerst',
      oldest: 'Älteste zuerst',
      lastEdited: 'Zuletzt bearbeitet',
      asc: 'A → Z',
      desc: 'Z → A',
      relevance: 'Relevanz'
    },
    resultsFor: 'Ergebnisse für:'
  },
  noEntries: 'Keine Einträge vorhanden.',
  noSearchEntries:
    'Keine Einträge gefunden. Bitte versuche es mit einem anderen Begriff...',
  entry: {
    item: {
      image: {
        pictureLoadingLabel:
          'Ladeindikator des Bildes (Originalbild des Eintrages {{solution}} wird geladen)',
        pictureAlt: 'Vorschaubild des Eintrages {{solution}}',
        pictureFallBackAlt:
          'Platzhalter Bild (Originalbild des Eintrages {{solution}} konnte nicht geladen werden oder existiert nicht)'
      },
      new: 'Neuen Eintrag erstellen',
      updatedAt: 'Geändert am {{date}}',
      submittedAt: 'Eingereicht am {{date}}',
      live: 'Live',
      placeholder: {
        name: 'Name der Lösung',
        filter: 'Kategorie',
        price: 'Auf Anfrage'
      }
    },
    detail: {
      contactForm: {
        topics: 'Thema',
        message: 'Nachricht',
        messageTo: 'Nachricht an {{organization}}',
        sendMessage: 'Nachricht senden'
      },
      error: {
        notFound:
          'Der Eintrag konnte nicht gefunden werden oder wurde bereits gelöscht!'
      },
      navigateTo: {
        edit: 'Zurück zur Bearbeitung',
        home: 'Zurück zur Startseite'
      },
      placeholder: {
        price: 'Auf Anfrage'
      },
      pictureAlt: 'Logo des Eintrages {{solution}}',
      contactUs: 'Kontakt aufnehmen',
      gallery: {
        pictureAlt: '{{count}}. Bild des Eintages {{solution}}',
        left: 'Linke Taste der Gallery',
        right: 'Rechte Taste der Gallery',
        video: {
          youtube: 'YouTube',
          vimeo: 'Vimeo',
          platform: 'Video Plattform',
          title: 'Externe Inhalte von {{platform}} laden?',
          description:
            'Beim Laden des Videos werden Daten an {{platform}} übermittelt. Mehr hierzu findest du in <0>unserer Datenschutzerklärung</0>.',
          enable: 'Video von {{platform}} laden'
        }
      },
      organization: {
        info: {
          title: 'Angaben ändern?',
          content:
            'Die Informationen zur Organisation werden aus dem DEUTSCHLAND.DIGITAL-Konto übernommen.<br></br>Du kannst die Angaben in deinem <0>DEUTSCHLAND.DIGITAL-Konto</0> bearbeiten.'
        },
        pictureAlt: 'Logo der Organization {{organization}}',
        contactUs: 'Kontakt aufnehmen',
        about: 'Über {{organization}}',
        detailedDescription:
          'An dieser Stelle erscheint die Langfassung der Organisationsbeschreibung.<br></br>Der Text kann über dein <0>DEUTSCHLAND.DIGITAL-Konto</0> bearbeitet werden.'
      },
      contact: {
        title: 'Mehr erfahren?',
        subtitle:
          'Nimm Kontakt auf mit {{organization}}, um weitere Informationen zu erhalten und die nächsten Schritte zu planen!',
        contactUs: 'Kontakt aufnehmen',
        modal: {
          topics: {
            title: 'Thema',
            buy: '"<0>{{solution}}</0>" erwerben',
            question: 'Fragen zu "<0>{{solution}}</0>"',
            other: 'Sonstiges'
          },
          planTogether: 'Zusammen die nächsten Schritte planen!',
          pictureAlt: 'Bild der Kontaktperson für {{solution}}'
        }
      },
      price: {
        priceInformation: 'Preisinformation',
        priceFor: 'Preise für {{solution}}'
      },
      target: {
        targetGroups: 'Zielgruppen',
        endDevices: 'Art der Lösung',
        minimumTerm: 'Mindestlaufzeit',
        categories: 'Kategorie'
      },
      declarations: {
        title: 'Schlüsselmerkmale',
        infoButton: 'Informationen zu den Schlüsselmerkmale anzeigen',
        notSpecified: 'Der Anbieter hat keine Merkmale angegeben.',
        specified: 'Der Anbieter hat die folgenden Merkmale angegeben:',
        modal: {
          title: 'Informationen zu Schlüsselmerkmalen',
          subtitle: 'Schlüsselmerkmale im Marktplatz von DEUTSCHLAND.DIGITAL',
          info: 'Anbieter haben die Möglichkeit, zutreffende Merkmale für ihre Lösung anzugeben. Folgende Merkmale stehen zur Auswahl: '
        },
        options: declarationOptions
      },
      testimonials: {
        pictureAlt: 'Bild des Autors ({{author}}) des Erfahrungsberichts',
        left: 'Linke Taste der Erfahrungsberichte',
        right: 'Rechte Taste der Erfahrungsberichte'
      },
      ratings: {
        title: 'Bewertung & Erfahrungen',
        ai: {
          title: 'KI-Checkup',
          status: 'BETA',
          description:
            'Der "KI-Checkup" bietet eine <strong>automatisierte Qualitätsbewertung</strong> von Softwarelösungen, bei der relevante Kriterien mithilfe <strong>künstlicher Intelligenz</strong> überprüft werden. <br></br><br></br>Da sich der KI-Checkup noch in der <strong>Erprobungsphase</strong> befindet, kann es zu Ungenauigkeiten oder Fehlern in den Bewertungen kommen.',
          criterion: {
            notRated: 'Nicht bewertet',
            top: 'TOP'
          },
          updated: 'Zuletzt geprüft am {{date}}'
        },
        experience: {
          title: 'Erfahrungen mit {{solution}}',
          description:
            'Gemeinsam lernen wir schneller! Bringt eure eigenen Erfahrungen in die Gemeinschaft ein oder tretet in Kontakt mit Kommunen, die bereits Erfahrungen gesammelt haben.',
          empty:
            'Noch keine Einträge. Werdet Vorreiter und teilt eure Erfahrungen!',
          report: {
            pictureAlt: 'Logo der Organization {{organization}}',
            author: 'Angegeben von {{name}}',
            recommendation: 'Weiterempfehlung:',
            emptyState: {
              organization: 'Kommune XY',
              author: 'Max Mustermann'
            }
          },
          contactForm: {
            modalTitle: 'Kontakt aufnehmen',
            title: 'Nimm Kontakt auf und tausche Erfahrungen aus!',
            pictureAlt: 'Bild der {{solution}} Organization'
          },
          form: {
            title: 'Erfahrungen mit {{solution}}?',
            description:
              'Mach für andere erkennbar, dass <strong>{{organization}}</strong> bereits Erfahrungen mit <strong>{{solution}}</strong> gesammelt hat und für einen Erfahrungsaustausch zur Verfügung steht.',
            email: 'E-Mail für Anfragen',
            recommendation: 'Empfiehlst du {{solution}} weiter?',
            NOT_SPECIFIED: 'Keine Angabe',
            nameOption: 'Mein Name darf öffentlich sichtbar sein',
            publish: 'Veröffentlichen',
            edit: {
              title: 'Eintrag bearbeiten',
              description:
                'Mach für andere erkennbar, dass {{organization}} bereits Erfahrungen mit {{solution}}gesammelt hat und für einen Erfahrungsaustausch zur Verfügung steht.',
              info: 'Hinweis: Es wird stets der Name der Person angezeigt, die den Eintrag zuletzt bearbeitet hat.',
              delete: 'Löschen',
              cancel: 'Abbrechen',
              update: 'Aktualisieren'
            },
            delete: {
              title: 'Eintrag löschen?',
              content:
                'Durch das Löschen geht die Chance verloren, Erfahrungen mit anderen zu teilen. Bist du sicher, dass du fortfahren möchtest?',
              cancel: 'Abbrechen',
              confirm: 'Eintrag löschen'
            },
            error: {
              email: {
                required: 'E-Mail ist ein Pflichtfeld.',
                invalid: 'Bitte gib eine gültige E-Mail-Adresse an.'
              }
            }
          },
          confirmation: {
            title: 'Vielen Dank für deinen Beitrag!',
            description:
              '<strong>Dein Einsatz hilft, die Zusammenarbeit zwischen Kommunen zu stärken!</strong><br></br> Die Angaben können von dir oder andere Mitglieder deiner Organisation jederzeit bearbeitet werden.'
          }
        }
      }
    },
    create: {
      preventNavigation: {
        title: 'Seite verlassen?',
        content:
          'Es sind noch nicht gespeicherte Angaben vorhanden. Die Daten gehen verloren, wenn du ohne zu speichern fortfährst.',
        cancel: 'Abbrechen',
        confirm: 'Ohne Speichern fortfahren'
      },
      pageHead: {
        review: {
          title: 'Eintrag bearbeiten',
          description:
            'Korrigiere Fehler oder nimm kleine Anpassungen vor.\n Größere Änderungen obliegen dem Anbieter!'
        },
        create: {
          title: 'Neuen Eintrag erstellen',
          description: 'Dein Marktplatzeintrag in wenigen Schritten!'
        },
        update: {
          title: 'Deinen Eintrag bearbeiten',
          description: 'Dein Marktplatzeintrag in wenigen Schritten!'
        }
      },
      error: {
        notFound:
          'Der Eintrag konnte nicht gefunden werden oder wurde bereits gelöscht!',
        partialError: 'Hier stimmt etwas noch nicht. Bitte prüfe die Angaben.'
      },
      updatedAt: 'Letzte Aktualisierung am {{date}} um {{time}}',
      prompts: {
        delete: {
          confirm: {
            title: 'Eintrag löschen?',
            content:
              'Dein Eintrag wird unwiderruflich gelöscht. Möchtest du fortfahren?',
            cancel: 'Abbrechen',
            confirm: 'Eintrag löschen'
          }
        },
        submit: {
          confirm: {
            title: 'Zur Prüfung einreichen?',
            content:
              'Dein Eintrag wird zur Prüfung eingereicht. Änderungen sind weiterhin möglich. Möchtest du fortfahren?',
            cancel: 'Abbrechen',
            confirm: 'Einreichen'
          },
          success: {
            title: 'Erfolgreich eingereicht!',
            content:
              'Vielen Dank! Unser Team wird so schnell wie möglich mit der Prüfung starten.',
            confirm: 'Alles klar!'
          }
        },
        publish: {
          confirm: {
            title: 'Eintrag veröffentlichen?',
            content:
              'Dein Eintrag wird auf dem DEUTSCHLAND.DIGITAL Marktplatz veröffentlicht. Möchtest du fortfahren?',
            cancel: 'Abbrechen',
            confirm: 'Veröffentlichen'
          },
          checkboxLabel: 'Ich stimme den <0>Nutzungsbedingungen</0> zu.',
          success: {
            title: 'Eintrag veröffentlicht',
            content:
              'Dein Eintrag wurde erfolgreich auf dem DEUTSCHLAND.DIGITAL Marktplatz veröffentlicht!',
            confirm: 'Alles klar!'
          }
        },
        unpublish: {
          confirm: {
            title: 'Veröffentlichung aufheben?',
            content:
              'Dein Eintrag wird vom DEUTSCHLAND.DIGITAL Marktplatz entfernt. Möchtest du fortfahren?',
            cancel: 'Abbrechen',
            confirm: 'Veröffentlichung aufheben'
          },
          success: {
            title: 'Eintrag aufgehoben',
            content:
              'Dein Eintrag wurde erfolgreich vom DEUTSCHLAND.DIGITAL Marktplatz entfernt!',
            confirm: 'Alles klar!'
          }
        }
      },
      preview: {
        page: 'Produktseite',
        item: 'Vorschaukachel',
        entry: {
          name: 'Name der Lösung',
          shortDescription: 'An dieser Stelle erscheint die Kurzbeschreibung!',
          subHeadline: 'Slogan',
          priceInformation: 'Preis',
          description:
            'An dieser Stelle erscheint die Beschreibung! Diese sollte sich an Kommunen richten und unter anderem folgende Fragen beantworten:<br></br><ul><li>Was bietet die Lösung einer Kommune?</li><li>Welche Herausforderungen werden adressiert?</li><li>Welche Funktionen werden angeboten?</li><li>Welche technischen Voraussetzungen gibt es?</li></ul>',
          contact: { name: 'Vorname Nachname' },
          testimonials: {
            text: 'Zitat der Person in Bezug auf die Lösung',
            authorName: 'Vorname Nachname',
            authorRole: 'Position & Organisation'
          },
          faqs: {
            question: 'Was bedeutet FAQ?',
            answer:
              'FAQ kommt aus dem Englischen und steht für „frequently asked questions“ - auf Deutsch “häufig gestellte Fragen”.'
          }
        }
      },
      formErrors: {
        logoId: 'Bitte wähle ein Logo für die Lösung aus.',
        name: 'Bitte gib einen Namen für die Lösung ein.',
        subHeadline: 'Slogan ist ein Pflichtfeld.',
        categories: 'Bitte wähle mindestens eine Kategorie aus.',
        targetGroups: 'Bitte wähle mindestens eine Zielgruppe aus.',
        shortDescription: 'Kurzbeschreibung ist ein Pflichtfeld.',
        description: 'Beschreibung ist ein Pflichtfeld.',
        previewImageId: 'Vorschaubild ist ein Pflichtfeld.',
        testimonials: {
          authorName: 'Vor- und Nachname ist ein Pflichtfeld.',
          authorRole: 'Position & Organisation ist ein Pflichtfeld.',
          authorPictureId: 'Profilbild ist ein Pflichtfeld.',
          text: 'Zitat ist ein Pflichtfeld.'
        },
        faq: {
          question: 'Frage ist ein Pflichtfeld.',
          answer: 'Antwort ist ein Pflichtfeld.'
        },
        contactPerson: {
          name: 'Vor- und Nachname ist ein Pflichtfeld.',
          email: 'E-Mail ist ein Pflichtfeld.',
          invalidEmail: 'Bitte gib eine gültige E-Mail-Adresse an.',
          invalidWebsite: 'Bitte gib eine gültige URL an.'
        },
        priceInformation: 'Preis ist ein Pflichtfeld.'
      },
      baseInformation: {
        title: 'Eckdaten',
        name: 'Name der Lösung*',
        logo: 'Logo*',
        subHeadline: {
          title: 'Slogan*',
          placeholder: 'Deine Lösung in einem Satz…'
        },
        categories: 'Kategorien (min. 1, max. 2)*',
        targetGroups: {
          title: 'Zielgruppen (min. 1)*',
          info: 'An wen richtet sich die Lösung hauptsächlich?'
        },
        endDevices: {
          title: 'Art der Lösung'
        }
      },
      description: {
        title: 'Beschreibung',
        shortDescription: {
          title: 'Kurzbeschreibung*',
          info: 'Beschreibe in wenigen Worten, welchen Nutzen der Dienst bietet.',
          additionalInfo:
            'Die Kurzbeschreibung wird nur in der Übersicht aller Lösungen angezeigt.',
          placeholder: 'Was bietet die Lösung einer Kommune in wenigen Worten?'
        },
        description: {
          title: 'Beschreibung*',
          info: 'Beschreibe die Lösung und deren Funktionen.',
          placeholder:
            'Was bietet die Lösung einer Kommune?\nWelche Herausforderung werden adressiert?\nWelche Funktionen werden angeboten?\nWelche technischen Voraussetzungen gibt es?'
        },
        declarations: {
          title: 'Schlüsselmerkmale',
          info: 'Schlüsselmerkmale umfassen Eigenschaften, die für Landkreise und Kommunen einen hohen Stellenwert haben.',
          additionalInfo:
            'Wähle die Merkmale aus, welche für die Lösung zutreffend sind.',
          options: declarationOptions
        }
      },
      media: {
        title: 'Bilder & Videos',
        previewImage: {
          title: 'Vorschaubild ({{count}}/{{maxCount}})*',
          info: 'Das Vorschaubild wird in der Liste aller Lösungen angezeigt.',
          additionalInfo:
            'Empfohlen wird ein Verhältnis von 16:9 mit min. 640 x 360 Pixel.',
          formNameSuffix: 'Vorschaubild des Eintrages'
        },
        media: {
          title: 'Medien-Galerie ({{count}}/{{maxCount}})',
          info: 'Füge bis zu 10 Bilder und Videos hinzu.',
          additionalInfo:
            'Die Galerie wird ganz oben auf der Lösungsseite angezeigt.',
          subTitle: 'Bilder',
          formNameSuffix: 'Bild des Eintrages'
        }
      },
      testimonials: {
        title: 'Testimonials',
        titleCount: 'Testimonials {{count}}/{{maxCount}}',
        info: 'Füge aussagekräftige Zitate über die Lösung hinzu.',
        additionalInfo:
          'Bitte stelle sicher, dass die genannten Personen Ihre Einwilligung erteilt haben.',
        form: {
          authorPicture: 'Profilbild*',
          title: 'Testimonial {{index}}',
          authorName: 'Vor- und Nachname*',
          authorRole: {
            title: 'Position & Organisation*',
            placeholder: 'Bürgermeisterin von Digitalhausen'
          },
          text: {
            title: 'Zitat*',
            placeholder: 'Ein Zitat der Person in Bezug auf die Lösung…'
          }
        },
        add: {
          new: 'Testimonial hinzufügen',
          additional: 'Weiteres Testimonial hinzufügen'
        }
      },
      faqs: {
        title: 'FAQs',
        titleCount: 'FAQs {{count}}/{{maxCount}}',
        info: 'Füge häufige Fragen und deren Antworten in Bezug auf die Lösung hinzu.',
        additionalInfo:
          ' Welche typischen Fragen stellen sich Kommunen vor dem Erwerb der Lösung?',
        form: {
          title: 'Frage {{index}}',
          question: 'Frage*',
          answer: 'Antwort*'
        },
        add: {
          new: 'Frage hinzufügen',
          additional: 'Weitere Frage hinzufügen'
        }
      },
      priceAndContact: {
        title: 'Preise & Kontakt',
        priceInformation: {
          title: 'Preis*',
          info: 'Wähle eine bestehende Preisbeschreibung aus oder füge eine eigene Beschreibung hinzu.',
          pleaseSelect: 'Bitte wählen...',
          options: {
            onRequest: 'Auf Anfrage',
            free: 'Kostenlos',
            ownDescription: 'Eigene Beschreibung'
          },
          ownDescription: {
            placeholder:
              'Z. B.: 75,00 Euro pro Monat, 2,00 Euro pro Nutzer:in, Ab 100,00 Euro'
          },
          longPriceInformation: {
            title: 'Ausführliche Preisinformation',
            info: 'Füge ausführliche Informationen zur Preisgestaltung hinzu.',
            additionalInfo:
              'Die Preisinformation kann durch Klick auf den Preis innerhalb der Lösungsseite aufgerufen werden.'
          }
        },
        contactPerson: {
          logo: 'Profilbild',
          title: 'Kontaktperson*',
          info: 'Füge die Kontaktdaten einer Ansprechperson hinzu, die bei Interesse einer Kommune kontaktiert werden soll und die Anfragen per E-Mail erhält.',
          name: 'Vor- und Nachname*',
          email: 'E-Mail*',
          phone: 'Telefon',
          website: 'Webseite'
        }
      },
      actions: {
        why: 'Warum?',
        hints: 'Hinweise',
        preview: 'Vorschau',
        reviewCommentModal: {
          title: 'Nachricht vom Team:'
        },
        save: 'Speichern',
        submit: 'Einreichen',
        publish: 'Veröffentlichen',
        unpublish: 'Veröffentlichung aufheben',
        menuLabel: 'Weitere Optionen',
        delete: 'Löschen',
        cancel: 'Beenden'
      }
    },
    review: {
      error: {
        notFound:
          'Der Eintrag konnte nicht gefunden werden oder wurde bereits gelöscht!'
      },
      navigateTo: {
        overview: 'Zurück zur Übersicht',
        edit: 'Bearbeitungsmodus öffnen'
      },
      submittedAt: 'Einsendedatum',
      publishedAt: 'Live seit {{date}}',
      reject: 'Ablehnen',
      approve: 'Akzeptieren',
      withdraw: 'Entscheidung widerrufen',
      remove: 'Aus Marktplatz entfernen',
      page: 'Produktseite',
      item: 'Vorschaukachel',
      prompts: {
        approve: {
          confirm: {
            title: 'Eintrag akzeptieren',
            content: 'Hinweise',
            cancel: 'Abbrechen',
            confirm: 'Eintrag akzeptieren'
          },
          success: {
            title: 'Eintrag akzeptiert!',
            content:
              'Der Eintrag wurde genehmigt und ist bereit, auf dem DEUTSCHLAND.DIGITAL Marktplatz veröffentlicht zu werden!',
            confirm: 'Alles klar!'
          }
        },
        reject: {
          confirm: {
            title: 'Eintrag ablehnen',
            content: 'Begründung',
            cancel: 'Abbrechen',
            confirm: 'Eintrag ablehnen'
          },
          success: {
            title: 'Erfolgreich abgelehnt!',
            content:
              'Der Eintrag wurde abgelehnt. Der Anbieter der Lösung wird über die Entscheidung benachrichtigt.',
            confirm: 'Alles klar!'
          }
        },
        withdraw: {
          confirm: {
            title: 'Entscheidung widerrufen',
            content: 'Begründung',
            cancel: 'Abbrechen',
            confirm: 'Entscheidung widerrufen'
          },
          success: {
            title: 'Status zurückgesetzt',
            content:
              'Der Status des Eintrages wurde zurückgesetzt. Der Anbieter der Lösung wird über die Entscheidung benachrichtigt.',
            confirm: 'Alles klar!'
          }
        },
        remove: {
          confirm: {
            title: 'Aus Marktplatz entfernen',
            content: 'Begründung',
            cancel: 'Abbrechen',
            confirm: 'Eintrag entfernen'
          },
          success: {
            title: 'Aus Marktplatz entfernt',
            content:
              'Der Eintrag wurde aus dem Marktplatz entfernt. Der Anbieter der Lösung wird über die Entscheidung benachrichtigt.',
            confirm: 'Alles klar!'
          }
        }
      },
      input: {
        placeholder: {
          approve:
            'Vermerke, ob kleinere Korrekturen oder Anpassungen an dem Eintrag vorgenommen werden müssen.',
          reject:
            'Erkläre, warum der Eintrag abgelehnt wurde und was korrigiert werden muss.',
          withdraw:
            'Erkläre, warum der Status des Eintrages zurückgesetzt werden muss.',
          remove:
            'Erkläre, warum der Eintrag aus dem Marktplatz entfernt wurde und was ggf. korrigiert werden muss.'
        },
        error: 'Begründung ist ein Pflichtfeld.'
      }
    }
  }
};
