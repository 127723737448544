import React, { useCallback, useEffect } from 'react';
import {
  Blocker,
  BlockerFunction,
  useBeforeUnload,
  useBlocker
} from 'react-router';

// Source: https://github.com/sshyam-gupta/react-router-prompt

/**
 * Because of react router update we are currently having issues with libraries using react router
 * In shared library we updated the code and removed the library usage (props are passed now)
 * This is an external library and we are still waiting for a fix (https://github.com/sshyam-gupta/react-router-prompt/issues/169)
 * Therefore we will include the code of the library for now here until the issue is fixed
 */

export type InitialStateType = {
  isActive: boolean;
  onConfirm(): void;
  resetConfirmation(): void;
};

export const useConfirm = (
  when: boolean | BlockerFunction
): InitialStateType => {
  const blocker = usePrompt(when);

  const resetConfirmation = () => {
    if (blocker.state === 'blocked') blocker.reset();
  };

  const onConfirm = () => {
    if (blocker.state === 'blocked') setTimeout(blocker.proceed, 0);
  };

  return {
    isActive: blocker.state === 'blocked',
    onConfirm,
    resetConfirmation
  };
};

export const usePrompt = (when: boolean | BlockerFunction): Blocker => {
  const blocker = useBlocker(when);

  useEffect(() => {
    if (blocker.state === 'blocked' && !when) {
      blocker.reset();
    }
  }, [blocker, when]);

  useBeforeUnload(
    useCallback(
      (event) => {
        if (
          (typeof when === 'boolean' && when === true) ||
          // @ts-expect-error Reload case -- No location present
          (typeof when === 'function' && when())
        ) {
          event.preventDefault();
          // eslint-disable-next-line no-param-reassign
          event.returnValue = 'Changes that you made may not be saved.';
        }
      },
      [when]
    ),
    { capture: true }
  );

  return blocker;
};

export type ReactRouterPromptProps = {
  when: boolean | BlockerFunction;
  children: (data: {
    isActive: boolean;
    onCancel: () => void;
    onConfirm: () => void;
  }) => React.ReactNode;
  beforeCancel?: () => Promise<unknown>;
  beforeConfirm?: () => Promise<unknown>;
};

export const ReactRouterPrompt = ({
  when,
  children,
  beforeCancel,
  beforeConfirm
}: ReactRouterPromptProps) => {
  const { isActive, onConfirm, resetConfirmation } = useConfirm(when);

  const onConfirmAction = useCallback(async () => {
    if (beforeConfirm) await beforeConfirm();
    onConfirm();
  }, [beforeConfirm, onConfirm]);

  const onResetAction = useCallback(async () => {
    if (beforeCancel) await beforeCancel();
    resetConfirmation();
  }, [beforeCancel, resetConfirmation]);

  if (isActive) {
    return (
      <>
        {children({
          isActive: true,
          onConfirm: onConfirmAction,
          onCancel: onResetAction
        })}
      </>
    );
  }
  return null;
};
