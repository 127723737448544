/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Mher Ter-Tovmasyan, Emily Calvet,
 * Milad Chatrangoon, Steffen Hupp, Philipp Ewen, Pedram (Majid) Jokar, Bestin John
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import {
  SLRAccordion,
  SLRFormInfo,
  SLRLabelIcon,
  emptyAsFallback,
  getIcon,
  hasValue,
  isEmptyOrNull,
  moveFromTo
} from '@SLR/shared-library';
import { FormWrapper } from 'components';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { EntryInput, FAQ_MAX_COUNT, accordionProps, emptyFaq } from '../..';
import { FaqForm } from './form/FaqForm';

export const Faqs: FC = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'marketplace.entry.create.faqs'
  });

  const [activeFaqKey, setActiveFaqKey] = useState<string>();

  const {
    formState: { errors },
    control
  } = useFormContext<EntryInput>();

  const { fields, append, remove, move } = useFieldArray({
    control,
    name: 'faqs'
  });

  const isEmpty = useMemo(() => !hasValue(fields), [fields]);

  const [titles, setTitles] = useState<KeyValue>({
    0: undefined,
    1: undefined,
    2: undefined,
    3: undefined,
    4: undefined,
    5: undefined,
    6: undefined,
    7: undefined,
    8: undefined,
    9: undefined
  });

  const faqForms = useMemo(
    () =>
      fields.map((field, index) => ({
        key: `faq-${index}`,
        title: emptyAsFallback(
          t('form.title', {
            index: index + 1
          }),
          titles?.[index] ?? field.question
        ),
        children: (
          <FaqForm
            id={field.id}
            index={index}
            onTitleChange={(title) =>
              setTitles((prevTitles) => ({ ...prevTitles, [index]: title }))
            }
          />
        ),
        hasError: !!errors.faqs?.[index]
      })),
    [errors.faqs, fields, t, titles]
  );

  useEffect(() => {
    if (isEmptyOrNull(activeFaqKey)) setActiveFaqKey('faq-0');
  }, [activeFaqKey, setActiveFaqKey]);

  const handleAddFaq = useCallback(() => {
    if (fields.length < FAQ_MAX_COUNT) {
      append(emptyFaq);
      setActiveFaqKey(`faq-${fields.length}`);
    }
  }, [append, fields.length, setActiveFaqKey]);

  const handleDeleteFaq = useCallback(
    (index: number) => {
      remove(index);
      setTitles((prevTitles) => {
        const titlesArray = Object.values(prevTitles);
        titlesArray.splice(index, 1);
        return {
          ...titlesArray
        } as unknown as KeyValue;
      });
    },
    [remove]
  );

  const handleMoveFaq = useCallback(
    (oldIndex: number, newIndex: number) => {
      move(oldIndex, newIndex);
      setTitles(
        (prevTitles) =>
          ({
            ...moveFromTo(Object.values(prevTitles), oldIndex, newIndex)
          }) as unknown as KeyValue
      );
      setActiveFaqKey(`faq-${newIndex}`);
    },
    [move, setActiveFaqKey]
  );

  return (
    <FormWrapper>
      <Row>
        <Col xs="12">
          <Form.Label htmlFor="addFAQ">
            {t('titleCount', {
              count: fields.length,
              maxCount: FAQ_MAX_COUNT
            })}
          </Form.Label>
          <SLRFormInfo text={t('info')} />
          <SLRFormInfo text={t('additionalInfo')} />
          <SLRAccordion
            {...accordionProps}
            className="mt-5 mb-3"
            isDraggable={fields.length > 1}
            isDeletable
            onDelete={handleDeleteFaq}
            onMove={handleMoveFaq}
            defaultActiveKey={activeFaqKey}
            onKeyUpdate={setActiveFaqKey}
            content={faqForms}
          />
        </Col>
        <Col xs="12" className={`d-flex ${isEmpty ? '' : 'flex-row-reverse'}`}>
          <Button
            id="addFAQ"
            variant="link"
            size="lg"
            disabled={fields.length >= FAQ_MAX_COUNT}
            onClick={handleAddFaq}
            className="px-0"
          >
            <SLRLabelIcon
              icon={getIcon('fal', 'plus')}
              size="lg"
              label={t(isEmpty ? 'add.new' : 'add.additional')}
            />
          </Button>
        </Col>
      </Row>
    </FormWrapper>
  );
};
