/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Mher Ter-Tovmasyan
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import {
  isEmptyOrNull,
  SLRModal,
  useGetOrganization
} from '@SLR/shared-library';
import { defaultOrganizationLogo } from 'assets';
import { ContactForm } from 'components';
import { useSendExperienceContactMessage } from 'hooks';
import { useOrganization } from 'providers';
import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import './ExperienceContactModal.scss';

export type ExperienceContactModalProps = {
  reportId: string;
  organizationId: string;
  onClose: () => void;
};

export const ExperienceContactModal: FC<ExperienceContactModalProps> = ({
  reportId,
  organizationId,
  onClose
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'marketplace.entry.detail.ratings.experience.contactForm'
  });

  const { selectedOrganization } = useOrganization();
  const { data: organization } = useGetOrganization(organizationId);

  const { mutate: sendContactMessage, isPending } =
    useSendExperienceContactMessage(organization?.name);

  const handleSendContactMessage = useCallback(
    (message: string) => {
      if (
        reportId &&
        selectedOrganization?.organizationId &&
        !isEmptyOrNull(message)
      ) {
        sendContactMessage(
          {
            id: reportId,
            organizationId: selectedOrganization?.organizationId,
            experienceContactMessageRequest: {
              customerOrganizationName:
                selectedOrganization?.organizationName ?? '',
              message
            }
          },
          {
            onSuccess: onClose
          }
        );
      }
    },
    [
      reportId,
      selectedOrganization?.organizationId,
      selectedOrganization?.organizationName,
      sendContactMessage,
      onClose
    ]
  );

  return (
    <SLRModal
      isLoading={isPending}
      backdrop="static"
      title={t('modalTitle')}
      isOpen
      onHide={onClose}
      onClose={onClose}
      size="xl"
      fullscreen="md-down"
      className="experience-contact-modal"
    >
      <div className="p-0 p-sm-4">
        <ContactForm
          titel={t('title')}
          pictureAltFallback={t('pictureAlt', {
            organization: organization?.name
          })}
          pictureFallback={defaultOrganizationLogo}
          contactName={organization?.name}
          isSendDisabled={isPending}
          onSendContactMessage={handleSendContactMessage}
        />
      </div>
    </SLRModal>
  );
};
