/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Mher Ter-Tovmasyan, Emily Calvet,
 * Milad Chatrangoon, Steffen Hupp, Philipp Ewen, Pedram (Majid) Jokar, Bestin John
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import {
  SLRAuthProvider,
  SLRFormProvider,
  SLRLibraryProvider,
  SLRStatisticProvider,
  SLRToastProvider,
  SLRVideoPlayerProvider
} from '@SLR/shared-library';
import 'assets/icons';
import {
  authConfiguration,
  formConfiguration,
  libraryConfiguration,
  statisticConfiguration,
  toastConfiguration
} from 'configs';
import { Layout } from 'layout';
import 'localization';
import { MarketplaceRouting } from 'pages';
import {
  AccessProvider,
  MarketplaceProvider,
  OrganizationProvider
} from 'providers';
import React, { FC } from 'react';
import { createRoot } from 'react-dom/client';
import {
  Outlet,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements
} from 'react-router';
import 'theme/index.scss';

const container = document.getElementById('root');
if (!container) throw new Error('Failed to find the root element');
const root = createRoot(container);

const Marketplace: FC = () => (
  <MarketplaceProvider>
    <OrganizationProvider>
      <AccessProvider>
        <Layout>
          <Outlet />
        </Layout>
      </AccessProvider>
    </OrganizationProvider>
  </MarketplaceProvider>
);

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<Marketplace />}>
      <Route path="*" element={<MarketplaceRouting />} />
    </Route>
  )
);

root.render(
  <SLRStatisticProvider {...statisticConfiguration}>
    <SLRAuthProvider {...authConfiguration}>
      <React.StrictMode>
        <SLRLibraryProvider {...libraryConfiguration}>
          <SLRToastProvider {...toastConfiguration}>
            <SLRFormProvider {...formConfiguration}>
              <SLRVideoPlayerProvider>
                <RouterProvider router={router} />
              </SLRVideoPlayerProvider>
            </SLRFormProvider>
          </SLRToastProvider>
        </SLRLibraryProvider>
      </React.StrictMode>
    </SLRAuthProvider>
  </SLRStatisticProvider>
);
