/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Mher Ter-Tovmasyan, Emily Calvet,
 * Milad Chatrangoon, Steffen Hupp, Philipp Ewen, Pedram (Majid) Jokar, Bestin John
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import { EntryDto } from '@SLR/marketplaceService-sdk';
import { getIcon, isEmptyOrNull } from '@SLR/shared-library';
import { defaultPersonLogo } from 'assets';
import { ContactForm, ContactTooltip } from 'components';
import { useSendContactMessage } from 'hooks';
import { useAccess, useOrganization } from 'providers';
import { Dispatch, FC, SetStateAction, useCallback, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { EntryModalContentType } from '..';

export type ContactModalProps = {
  entry?: Nullable<EntryDto>;
  organizationName?: string;
  onLoading: Dispatch<SetStateAction<boolean>>;
  onChange: Dispatch<SetStateAction<EntryModalContentType>>;
};

export const ContactModal: FC<ContactModalProps> = ({
  entry,
  organizationName,
  onLoading,
  onChange
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'marketplace.entry.detail.contact.modal'
  });

  const { isDemoAccess } = useAccess();
  const { selectedOrganization } = useOrganization();

  const { mutate: sendContactMessage, isPending } =
    useSendContactMessage(organizationName);

  const topics = useMemo(
    () => [
      {
        value: t('topics.buy', {
          solution: entry?.name
        }),
        label: (
          <Trans
            t={t}
            i18nKey="topics.buy"
            values={{ solution: entry?.name }}
            components={[<span className="topic-text" key="topics.buy" />]}
          />
        )
      },
      {
        value: t('topics.question', {
          solution: entry?.name
        }),
        label: (
          <Trans
            t={t}
            i18nKey="topics.question"
            values={{ solution: entry?.name }}
            components={[<span className="topic-text" key="topics.question" />]}
          />
        )
      },
      { value: t('topics.other'), label: <>{t('topics.other')}</> }
    ],
    [entry?.name, t]
  );

  const handleSendContactMessage = useCallback(
    (message: string, topic?: string) => {
      if (entry?.id && topic && !isEmptyOrNull(message)) {
        onLoading(true);
        sendContactMessage(
          {
            id: entry.id,
            organizationId: selectedOrganization?.organizationId ?? '',
            contactMessageRequest: {
              // <0> and </0> must be deleted to adapt topic name for email template
              topic: topic.replace('<0>', '').replace('</0>', ''),
              customerOrganizationName:
                selectedOrganization?.organizationName ?? '',
              message
            }
          },
          {
            onSettled: () => onLoading(false),
            onSuccess: () => onChange(EntryModalContentType.None)
          }
        );
      }
    },
    [
      entry?.id,
      selectedOrganization?.organizationId,
      selectedOrganization?.organizationName,
      onLoading,
      sendContactMessage,
      onChange
    ]
  );

  return (
    <ContactForm
      titel={t('planTogether')}
      pictureId={entry?.contact?.pictureId}
      pictureAltFallback={t('pictureAlt', { solution: entry?.name })}
      pictureFallback={defaultPersonLogo}
      contactName={entry?.contact?.name}
      organizationName={organizationName}
      topics={topics}
      initialTopicValue={t('topics.buy', { solution: entry?.name })}
      contactToolTips={
        <>
          {entry?.contact?.email && (
            <ContactTooltip
              content={entry?.contact.email}
              link={`mailto:${entry?.contact.email}`}
              icon={getIcon('fal', 'envelope-circle')}
              isDisabled={isDemoAccess}
            />
          )}
          {entry?.contact?.phone && (
            <ContactTooltip
              content={entry?.contact.phone}
              link={`tel:${entry?.contact.phone}`}
              icon={getIcon('fal', 'phone-circle')}
              isDisabled={isDemoAccess}
            />
          )}
          {entry?.contact?.website && (
            <ContactTooltip
              content={entry?.contact.website}
              link={`${entry?.contact.website}`}
              icon={getIcon('fal', 'globe')}
              isDisabled={isDemoAccess}
            />
          )}
        </>
      }
      isSendDisabled={isPending}
      onSendContactMessage={handleSendContactMessage}
      onOrganizationClick={() =>
        onChange(EntryModalContentType.OrganizationDetail)
      }
    />
  );
};
