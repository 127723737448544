/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Mher Ter-Tovmasyan, Emily Calvet,
 * Milad Chatrangoon, Steffen Hupp, Philipp Ewen, Pedram (Majid) Jokar, Bestin John
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import {
  EntryDtoCategoriesEnum,
  EntryDtoEndDevicesEnum,
  EntryDtoTargetGroupsEnum
} from '@SLR/marketplaceService-sdk';
import { Ownership } from '@SLR/media-service-sdk';
import {
  SLRCheckboxGroup,
  SLRFormInfo,
  SLRLogoUpload,
  SLRMediaSize
} from '@SLR/shared-library';
import { FormWrapper } from 'components';
import { FC } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  CATEGORIES_MAX_COUNT,
  EntryInput,
  logoEditorIcons,
  NAME_MAX_CHAR_LENGTH,
  SUB_HEADLINE_MAX_CHAR_LENGTH
} from '../..';

export const BaseInformation: FC = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'marketplace.entry.create.baseInformation'
  });

  const {
    formState: { errors },
    getValues,
    control,
    watch,
    register
  } = useFormContext<EntryInput>();

  const nameCount = watch('name')?.length ?? 0;
  const subHeadlineCount = watch('subHeadline')?.length ?? 0;

  return (
    <FormWrapper>
      <Row className="flex-column flex-lg-row-reverse base-information-forms">
        <Col
          xs="auto"
          lg="4"
          xl="3"
          className={`text-center ${errors.logoId ? 'text-danger' : ''}`}
        >
          <Form.Label htmlFor="logo">{t('logo')}</Form.Label>
          <SLRLogoUpload
            id="logo"
            name="logoId"
            icons={logoEditorIcons}
            control={control}
            mediaSize={SLRMediaSize.Small}
            mediaOwner={{
              organizationId: getValues('organizationId') ?? undefined,
              owner: Ownership.Organization
            }}
          />
          <SLRFormInfo
            isInvalid={!!errors.logoId}
            text={errors?.logoId?.message}
          />
        </Col>
        <Col xs="12" lg="8" xl="9">
          <Row>
            <Col
              xs="12"
              lg="8"
              className={`pb-2 ${errors.name ? 'text-danger' : ''}`}
            >
              <Form.Label htmlFor="name">{t('name')}</Form.Label>
              <Form.Control
                id="name"
                type="text"
                autoComplete="true"
                maxLength={NAME_MAX_CHAR_LENGTH}
                className="form-control-lg"
                isInvalid={!!errors.name}
                {...register('name')}
              />
              <SLRFormInfo
                isInvalid={!!errors?.name}
                text={errors?.name?.message}
                counter={{
                  count: nameCount,
                  maxCount: NAME_MAX_CHAR_LENGTH
                }}
              />
            </Col>
            <Col xs="12" className={errors.subHeadline ? 'text-danger' : ''}>
              <Form.Label htmlFor="subHeadline">
                {t('subHeadline.title')}
              </Form.Label>
              <Form.Control
                id="subHeadline"
                type="text"
                as="textarea"
                rows={2}
                maxLength={SUB_HEADLINE_MAX_CHAR_LENGTH}
                placeholder={t('subHeadline.placeholder')}
                className="form-control-lg"
                isInvalid={!!errors.subHeadline}
                {...register('subHeadline')}
              />
              <SLRFormInfo
                isInvalid={!!errors.subHeadline}
                text={errors.subHeadline?.message}
                counter={{
                  count: subHeadlineCount,
                  maxCount: SUB_HEADLINE_MAX_CHAR_LENGTH
                }}
              />
            </Col>
            <Col xs="12" className="pt-2 pb-5">
              <fieldset className={errors.categories ? 'text-danger' : ''}>
                <legend className="form-label">{t('categories')}</legend>
                <SLRCheckboxGroup
                  name="categories"
                  control={control}
                  className="mb-2"
                  options={Object.values(EntryDtoCategoriesEnum)}
                  allowedNumberOfSelectedOptions={CATEGORIES_MAX_COUNT}
                />
                <SLRFormInfo
                  isInvalid={!!errors?.categories}
                  text={errors?.categories?.message}
                />
              </fieldset>
            </Col>
            <Col xs="12" className="pb-5">
              <fieldset className={errors.targetGroups ? 'text-danger' : ''}>
                <legend className="form-label">
                  {t('targetGroups.title')}
                </legend>
                <SLRFormInfo text={t('targetGroups.info')} />
                <SLRCheckboxGroup
                  name="targetGroups"
                  control={control}
                  className="mb-2"
                  options={Object.values(EntryDtoTargetGroupsEnum)}
                />
                <SLRFormInfo
                  isInvalid={!!errors?.targetGroups}
                  text={errors?.targetGroups?.message}
                />
              </fieldset>
            </Col>
            <Col xs="12">
              <fieldset className={errors.endDevices ? 'text-danger' : ''}>
                <legend className="form-label">{t('endDevices.title')}</legend>
                <SLRCheckboxGroup
                  name="endDevices"
                  control={control}
                  options={Object.values(EntryDtoEndDevicesEnum)}
                  colSize={12}
                />
              </fieldset>
            </Col>
          </Row>
        </Col>
      </Row>
    </FormWrapper>
  );
};
