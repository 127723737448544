/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Mher Ter-Tovmasyan
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import {
  SLRFormInfo,
  SLRMediaItem,
  getIcon,
  hasValue,
  isEmptyOrNull
} from '@SLR/shared-library';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAccess, useMarketplace } from 'providers';
import { FC, ReactNode, useCallback, useMemo, useState } from 'react';
import { Button, Col, Dropdown, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import './ContactForm.scss';

const TEXT_MAX_LENGTH = 1000;

export type ContactMessageTopic = {
  value: string;
  label: JSX.Element;
};

export type ContactFormProps = {
  titel: string;
  pictureId?: string;
  pictureAltFallback: string;
  pictureFallback: string;
  contactName?: string;
  organizationName?: string;
  topics?: ContactMessageTopic[];
  initialTopicValue?: string;
  contactToolTips?: ReactNode;
  isSendDisabled?: boolean;
  onSendContactMessage: (message: string, topic?: string) => void;
  onOrganizationClick?: () => void;
};

export const ContactForm: FC<ContactFormProps> = ({
  pictureId,
  pictureAltFallback,
  pictureFallback,
  contactName,
  organizationName,
  titel,
  topics,
  initialTopicValue = '',
  contactToolTips,
  isSendDisabled,
  onSendContactMessage,
  onOrganizationClick
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'marketplace.entry.detail.contactForm'
  });

  const { isDemoAccess } = useAccess();
  const { isReviewContext, isManagementContext } = useMarketplace();

  const [message, setMessage] = useState<string>('');
  const messageLength = useMemo(() => message.length, [message]);

  const [topic, setTopic] = useState<string>(initialTopicValue);

  const handleSendContactMessage = useCallback(
    () => onSendContactMessage(message, topic),
    [message, onSendContactMessage, topic]
  );

  return (
    <div className="contact-form">
      <Row className="align-items-center gap-4 gap-xl-0">
        <Col xs="12" xl="4" className="person">
          <Row className="justify-content-center">
            <Col xs="auto">
              <SLRMediaItem
                mediaItemId={pictureId}
                errorFallback={pictureFallback}
                roundedCircle
                altFallback={pictureAltFallback}
                className="picture mx-0"
              />
            </Col>

            <Col
              xs="12"
              className="d-flex flex-column align-items-center gap-2 mt-4 mb-5"
            >
              <Row>
                <Col className="fw-bold text-center name">{contactName}</Col>
              </Row>
              {!isEmptyOrNull(organizationName) && (
                <Row>
                  <Col
                    onClick={() => {
                      if (onOrganizationClick) onOrganizationClick();
                    }}
                    className={`text-center organization ${onOrganizationClick ? 'cursor-pointer text-decoration-underline' : ''}`}
                  >
                    {organizationName}
                  </Col>
                </Row>
              )}
            </Col>
            {!!contactToolTips && (
              <Col xs="auto">
                <Row className="gap-4 align-items-center">
                  {contactToolTips}
                </Row>
              </Col>
            )}
          </Row>
        </Col>
        <Col xs="12" xl="8" className="d-flex flex-column gap-4 message">
          <Row>
            <Col>
              <h2>{titel}</h2>
            </Col>
          </Row>
          {hasValue(topics) && (
            <Row>
              <Col>
                <Form.Label htmlFor="topic">{t('topics')}</Form.Label>
              </Col>

              <Col xs="12">
                <Dropdown id="topic">
                  <Dropdown.Toggle
                    variant="outline-neutral"
                    size="lg"
                    className="topic-toggle"
                  >
                    <div className="d-flex topic-text">
                      {topics.find((t) => t.value === topic)?.label}
                    </div>

                    <FontAwesomeIcon
                      className="ms-4"
                      icon={getIcon('fas', 'sort')}
                    />
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="w-100">
                    {topics.map(({ value, label }) => (
                      <Dropdown.Item
                        key={value}
                        active={value === topic}
                        className="topic-item"
                        onClick={() => setTopic(value)}
                      >
                        <span className="d-flex">{label}</span>
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
            </Row>
          )}
          <Row className="gap-2">
            <Col xs="12">
              <Form.Label htmlFor="message">
                {organizationName || contactName
                  ? t('messageTo', {
                      organization: organizationName ?? contactName
                    })
                  : t('message')}
              </Form.Label>
              <Form.Control
                id="message"
                as="textarea"
                rows={12}
                onChange={(ev) => setMessage(ev.target.value)}
                value={message}
              />
              <SLRFormInfo
                isInvalid={messageLength > TEXT_MAX_LENGTH}
                counter={{
                  count: messageLength,
                  maxCount: TEXT_MAX_LENGTH
                }}
              />
            </Col>
          </Row>
          <Row className="justify-content-end">
            <Col xs="auto">
              <Button
                disabled={
                  isEmptyOrNull(message) ||
                  messageLength > TEXT_MAX_LENGTH ||
                  isDemoAccess ||
                  isReviewContext ||
                  isManagementContext ||
                  isSendDisabled
                }
                variant="primary"
                onClick={() => handleSendContactMessage()}
              >
                <FontAwesomeIcon
                  icon={getIcon('fal', 'paper-plane')}
                  className="me-2"
                />
                {t('sendMessage')}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};
