import { SLRFormInfo } from '@SLR/shared-library';
import { FC, PropsWithChildren } from 'react';
import { Col, Row } from 'react-bootstrap';

export const FormWrapper: FC<PropsWithChildren> = ({ children }) => (
  <>
    {children}
    <Row className="pt-3">
      <Col xs="12" className="text-end">
        <SLRFormInfo text="form.mandatoryFields" />
      </Col>
    </Row>
  </>
);
