/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Mher Ter-Tovmasyan, Emily Calvet,
 * Milad Chatrangoon, Steffen Hupp, Philipp Ewen, Pedram (Majid) Jokar, Bestin John
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import { SLRFormInfo, SLRTextEditor } from '@SLR/shared-library';
import { FormWrapper } from 'components';
import { FC } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  DESCRIPTION_MAX_CHAR_LENGTH,
  EntryInput,
  SHORT_DESCRIPTION_MAX_CHAR_LENGTH
} from '../..';
import { DeclarationsForm } from './form/DeclarationsForm';

export const Description: FC = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'marketplace.entry.create.description'
  });

  const {
    control,
    formState: { errors },
    watch,
    register
  } = useFormContext<EntryInput>();

  const shortDescriptionCount = watch('shortDescription')?.length ?? 0;

  return (
    <FormWrapper>
      <Row>
        <Col
          xs="12"
          className={`pb-2 ${errors.shortDescription ? 'text-danger' : ''}`}
        >
          <Form.Label htmlFor="shortDescription">
            {t('shortDescription.title')}
          </Form.Label>
          <SLRFormInfo text={t('shortDescription.info')} />
          <SLRFormInfo text={t('shortDescription.additionalInfo')} />
          <Form.Control
            id="shortDescription"
            type="text"
            as="textarea"
            rows={4}
            maxLength={SHORT_DESCRIPTION_MAX_CHAR_LENGTH}
            placeholder={t('shortDescription.placeholder')}
            className="form-control-lg mt-3"
            isInvalid={!!errors.shortDescription}
            {...register('shortDescription')}
          />
          <SLRFormInfo
            isInvalid={!!errors.shortDescription}
            text={errors.shortDescription?.message}
            counter={{
              count: shortDescriptionCount,
              maxCount: SHORT_DESCRIPTION_MAX_CHAR_LENGTH
            }}
          />
        </Col>
        <Col xs="12" className={errors.description ? 'text-danger' : ''}>
          <Form.Label htmlFor="longDescription">
            {t('description.title')}
          </Form.Label>
          <SLRFormInfo text={t('description.info')} />
          <SLRTextEditor
            id="longDescription"
            control={control}
            name="description"
            className="mt-3"
            rows={10}
            placeholder={t('description.placeholder')}
            maxLength={DESCRIPTION_MAX_CHAR_LENGTH}
            isInvalid={!!errors.description}
            errorMessage={errors.description?.message}
          />
        </Col>
        <Col xs="12">
          <fieldset className={errors.declarations ? 'text-danger' : ''}>
            <legend className="form-label">{t('declarations.title')}</legend>
            <SLRFormInfo text={t('declarations.info')} />
            <SLRFormInfo text={t('declarations.additionalInfo')} />
            <DeclarationsForm name="declarations" control={control} />
          </fieldset>
        </Col>
      </Row>
    </FormWrapper>
  );
};
