/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Mher Ter-Tovmasyan, Emily Calvet,
 * Milad Chatrangoon, Steffen Hupp, Philipp Ewen, Pedram (Majid) Jokar, Bestin John
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import { getIcon, useVideoPlayer } from '@SLR/shared-library';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PRIVACY_POLICY_PATH } from 'configs';
import { FC, useMemo } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router';

import './VideoConfirmation.scss';

export type VideoConfirmationProps = {
  id: string;
  url: string;
  className?: string;
};

export const VideoConfirmation: FC<VideoConfirmationProps> = ({
  id,
  url,
  className = ''
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'marketplace.entry.detail.gallery.video'
  });

  const { enableVideo } = useVideoPlayer();

  const platform = useMemo(() => {
    if (url.includes('youtu.be') || url.includes('youtube'))
      return t('youtube');
    if (url.includes('vimeo')) return t('vimeo');
    return t('platform');
  }, [t, url]);

  return (
    <Container className={className}>
      <Row className="video-confirmation align-items-center p-0 p-sm-5 mx-0">
        <Col className="my-2 my-sx-4">
          <Row className="title">
            <Col>{t('title', { platform })}</Col>
          </Row>
          <Row className="subtitle pt-2 pb-4">
            <Col>
              <Trans
                t={t}
                i18nKey="description"
                values={{ platform }}
                components={[
                  <Link to={PRIVACY_POLICY_PATH} key="PRIVACY_POLICY_PATH" />
                ]}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Button
                variant="outline"
                size="lg"
                className="enable-button"
                onClick={() => enableVideo(id)}
              >
                <FontAwesomeIcon
                  icon={getIcon('fal', 'arrows-rotate')}
                  className="me-2"
                />
                {t('enable', { platform })}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};
