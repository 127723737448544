/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Mher Ter-Tovmasyan, Emily Calvet,
 * Milad Chatrangoon, Steffen Hupp, Philipp Ewen, Pedram (Majid) Jokar, Bestin John
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import { SLRMediaItem, hasValue } from '@SLR/shared-library';
import { defaultNewsLogo } from 'assets';
import { useGetAllNews } from 'hooks';
import { FC } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router';

import './NewsItem.scss';

export const NewsItem: FC = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'marketplace.hero.newsItem'
  });
  const { data: news } = useGetAllNews();

  return (
    <>
      {hasValue(news) && (
        <Container fluid className="news-item">
          <Container>
            <Row className="gap-4 flex-column-reverse flex-lg-row justify-content-center">
              <Col
                md="12"
                lg="4"
                className="px-4 p-4 d-flex align-items-center justify-content-center"
              >
                <SLRMediaItem
                  mediaItemId={news[0]?.pictureId}
                  altFallback={t('pictureAlt', {
                    header: news[0]?.header
                  })}
                  errorFallback={defaultNewsLogo}
                  className="news-item-image mw-100"
                />
              </Col>
              <Col
                md="12"
                lg="6"
                className="gap-4 p-4 d-flex flex-column justify-content-center align-items-center align-items-lg-start"
              >
                <Row className="news-item-name text-center text-lg-start">
                  <Col>{news[0].header}</Col>
                </Row>
                <Row>
                  <Col className="news-item-description">
                    {news[0].description}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Link
                      className="btn btn-outline-light btn-lg"
                      target={
                        /**
                         * If link contains http -> link is a valid url and page is opened in a new tab
                         * Else -> link contains an id of an entry -> open entry page in same tab
                         */
                        news[0].link.includes('http') ? '_blank' : '_self'
                      }
                      to={news[0].link}
                    >
                      {t('learnMore')}
                    </Link>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </Container>
      )}
    </>
  );
};
